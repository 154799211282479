import { Link } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'
import { Waypoint } from 'react-waypoint'
import Header from '../components/Header'
import Layout from '../components/layout'
import Nav from '../components/Nav'
import { graphql } from 'gatsby'
import Gallery from '@browniebroke/gatsby-image-gallery';

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stickyNav: false,
    }
    this.coffins = props.data.coffins.edges.map(({ node }) => node.childImageSharp);
    this.flowers = props.data.flowers.edges.map(({ node }) => node.childImageSharp);
    this.titleImage = props.data.titleImage.childImageSharp;
    this.teamImage = props.data.teamImage.childImageSharp;
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({ stickyNav: false }))
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }))
  }

  render() {

    return (
      <Layout>
        <Helmet title="Bestattungshaus Bartko" />

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        ></Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>Wir begrüßen Sie in unserem Familienunternehmen</h2>
                </header>
                <p>
                  Sie finden uns in <b>Köthen, Lohmannstraße 135</b>, schräg gegenüber dem roten Gebäude der „Hochschule Anhalt“ und sind uns jederzeit, auch zu einem Informationsbesuch herzlich willkommen.<br /><br />

                  Wir sind rund um die Uhr für Sie zu erreichen.
                  Telefonisch unter der Rufnummer  <b>&#9742; <a href="tel:03496-552939">(03496) 55 29 39</a></b> und da wir im Haus wohnen, auch fast immer persönlich.<br /><br />

                  Wir möchten Ihnen auf den folgenden Seiten unser Unternehmen vorstellen.
                </p>
              </div>
              <span className="image">
                <Gallery
                  images={[this.titleImage]}
                  mdColWidth={100}
                  colWidth={100}
                  imgClass="single-image-preview"
                />
              </span>
            </div>
          </section>
          <section id="services" className="main special">
            <ul className="features">
              <li>
                <h3>Bestattungen</h3>
                <p>
                  Wir bieten Erdbestattungen, Feuerbestattungen, Seebestattungen und Baumbestattungen sowie nötige Überführungen im In- und Ausland
                </p>
                <Link to="/bestattungen" className="button">
                  Mehr Infos
                </Link>
              </li>
              <li>
                <h3>Abschiednahme und Trauerfeier</h3>
                <p>
                  Jeder Trauerfall ist uns wichtig. Wir begleiten die Hinterbliebenden durch die schwere Zeit der Trauer und nehmen uns viel Zeit für persönliche Gespräche
                </p>
                <Link to="/begleitung" className="button">
                  Mehr Infos
                </Link>
              </li>
              <li>
                <h3>Bestattungsvorsorge</h3>
                <p>
                  "Heute schon an Morgen denken“ - Es ist oftmals sehr schwer, sich zu Lebzeiten mit dem Tod zu beschäftigen
                </p>
                <Link to="/vorsorge" className="button">
                  Mehr Infos
                </Link>
              </li>
            </ul>
          </section>
          <section id="help" className="main special">
            <header className="major">
              <h2>Hilfe im Trauerfall</h2>
            </header>
            <p style={{fontSize: '1.15em', marginBottom: '1rem'}}>
              Folgende Punkte sollten Sie im Trauerfall schnellstmöglich erledigen
            </p>
            <center>
              <ul style={{width: 'fit-content', textAlign: 'left', fontSize: '1.15em'}}>
                <li>Hausarzt oder Notarzt (&#9742; 112) oder den ärztlichen Notdienst (&#9742; 116 117) verständigen</li>
                <li>Personalausweis der verstorbenen Person bereithalten</li>
                <li>Mit einem Bestattungsunternehmen Ihres Vertrauens Kontakt aufnehmen</li>
              </ul>
            </center>
            <footer className="major">
              <ul className="actions">
                <li>
                  <Link to="/hilfe-im-trauerfall" className="button special">
                    Was sonst noch zu erledigen ist
                  </Link>
                </li>
              </ul>
            </footer>
          </section>
          <section id="team" className="main">
            <div className="spotlight spotlight--left">
              <span className="image">
                <Gallery
                  images={[this.teamImage]}
                  mdColWidth={100}
                  colWidth={100}
                  imgClass="single-image-preview"
                />
              </span>
              <div className="content">
                <header className="major">
                  <h2>Unsere Mitarbeiter</h2>
                </header>
                <p>
                  Hans Bartko gründete das Bestattungshaus Bartko am 20. September 1990 in Köthen. Seit dem 20. Januar 1994 ist das Bestattungshaus in der Lohmanstraße 135 zu finden. <br />
                  Mit seinem Engagement und viel Herzblut machte er das Unternehmen gemeinsam mit seiner Frau Rosemarie zu dem, was es heute ist.<br />
                  Der heutige Inhaber Holger Rösler (1. v.l.) ist der Ehemann von <br />Cathleen Rösler, geb. Bartko (2. v.l.). <br />
                  Cordelia Barthel, geb. Bartko (2. v.r.) - ebenfalls Tochter von Hans Bartko - arbeitet mit ihrem Ehemann Björn Barthel (1. v.r.) im Bestattungshaus.<br />
                  Maik Hartkopf (3. v.l.) ist ebenfalls Angestellter.
                </p>
              </div>
            </div>
          </section>

          <section id="images" className="main special">
            <header className="major">
              <h2>Särge und Urnen</h2>
              <p>
                Hier sehen Sie eine Auswahl unserer Särge und Urnen
              </p>
            </header>
            <div className="gallery">
              <Gallery images={this.coffins} />
            </div>
          </section>

          <section id="images" className="main special">
            <header className="major">
              <h2>Blumenschmuck</h2>
              <p>
                Hier sehen Sie eine Auswahl unseres Blumenschmucks
              </p>
            </header>
            <div className="gallery">
              <Gallery images={this.flowers} />
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query ImagesForGallery {
    coffins: allFile(
      filter: { relativeDirectory: { eq: "coffins" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              layout: CONSTRAINED
              transformOptions: {cropFocus: CENTER}
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
            meta: gatsbyImageData(layout: FIXED)
          }
        }
      }
    }
    flowers: allFile(
      filter: { relativeDirectory: { eq: "flowers" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 270
              height: 270
              layout: CONSTRAINED
              transformOptions: {cropFocus: CENTER}
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
            meta: gatsbyImageData(layout: FIXED)
          }
        }
      }
    }
    titleImage: file(name: {in: "home_entry"}) {
      childImageSharp {
        thumb: gatsbyImageData(
          height: 400
          placeholder: BLURRED
          layout: CONSTRAINED
        )
        full: gatsbyImageData(layout: FULL_WIDTH)
        meta: gatsbyImageData(layout: FIXED)
      }
    }
    teamImage: file(name: {in: "team-new2"}) {
      childImageSharp {
        thumb: gatsbyImageData(
          height: 400
          placeholder: BLURRED
          layout: CONSTRAINED
        )
        full: gatsbyImageData(layout: FULL_WIDTH)
        meta: gatsbyImageData(layout: FIXED)
      }
    }
  }
`

export default Index
