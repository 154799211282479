import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const Nav = (props) => (
    <nav id="nav" className={props.sticky ? 'alt' : ''}>
        <Scrollspy items={ ['intro', 'services', 'help', 'team', 'images'] } currentClassName="is-active" offset={-500}>
            <li>
                <Scroll type="id" element="intro">
                    <a href="#">Übersicht</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="services">
                    <a href="#">Leistungen</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="help">
                    <a href="#">Hilfe im Trauerfall</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="team">
                    <a href="#">Mitarbeiter</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="images">
                    <a href="#">Särge, Urnen & Blumen</a>
                </Scroll>
            </li>
        </Scrollspy>
    </nav>
)

export default Nav
